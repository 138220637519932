import React, {useEffect, useMemo, useState} from 'react';
import {Card, Container, FloatingLabel} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {user as userAtom} from "../../../atoms/user";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DataService from "../../../services/DataService";

export default function User() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [password, setPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [betwin, setBetwin] = useState<boolean>(false);
    const [enddate, setEnddate] = useState<boolean>(false);
    const [reportProcess, setReportProcess] = useState<boolean>(false);
    const [nextgame, setNextgame] = useState<boolean>(false);
    const [playerUpdate, setPlayerUpdate] = useState<boolean>(false);

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useRecoilState(userAtom);

    // @ts-ignore
    const params: { saison: number } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(user.token === "") {
            navigate("/saison/" + params.saison + "/login");
        }
        service.user().getConfig(params.saison, user.token, setUser, d => {
            setEmail(d.email);
            setBetwin(d.flags.betwin);
            setEnddate(d.flags.enddate);
            setReportProcess(d.flags.reportProcess);
            setNextgame(d.flags.nextgame);
            setPlayerUpdate(d.flags.playerUpdate);
        }, setLoading);
    }, [user.token, params.saison, navigate, setUser, service]);

    return (
        loading ?
            <div className="spinner-grow" role="status"/>
        :
            <Container>
                <br/>
                <br/>
                        <Card>
                            <Card.Header as="h5">User Daten</Card.Header>
                            <Card.Body>
                                {
                                    error && <Card.Subtitle>{error}</Card.Subtitle>
                                }
                                <Form>
                                    <FloatingLabel controlId="password" label="Neues Passwort" className="mb-3">
                                        <Form.Control type="password" placeholder={""} value={password} onChange={e => {
                                            setPassword(e.target.value);
                                            e.preventDefault();
                                        }}/>
                                    </FloatingLabel>
                                    <Button disabled={loading} onClick={event => {
                                        setLoading(true)
                                        service.user().updatePassword(params.saison, user.token, password, setUser, setError, setLoading);
                                        setPassword("");
                                    }}>Speicher Passwort</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>Email</Card.Header>
                            <Card.Body>
                                <Form>
                                    <FloatingLabel controlId="email" label="Email" className="mb-3">
                                        <Form.Control type="email" placeholder={""} value={email} onChange={e => {
                                            setEmail(e.target.value);
                                            e.preventDefault();
                                        }}/>
                                    </FloatingLabel>
                                    <Button disabled={loading} onClick={event => {
                                        setLoading(true)
                                        service.user().updateEmail(params.saison, user.token, email, setUser, setError, setLoading);
                                    }}>Speicher Email</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>Benachrichtigungen</Card.Header>
                            <Card.Body>
                                <Form>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="betwinId" defaultChecked={betwin} onClick={event => {
                                            setBetwin(!betwin);
                                        }}/>
                                        <label className="form-check-label" htmlFor="betwinId">Gewonnene Wette</label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="enddateId" defaultChecked={enddate} onClick={event => {
                                            setEnddate(!enddate);
                                        }}/>
                                        <label className="form-check-label" htmlFor="enddateId">Endzeiten gesetzt</label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="reportProcessId" defaultChecked={reportProcess} onClick={event => {
                                            setReportProcess(!reportProcess);
                                        }}/>
                                        <label className="form-check-label" htmlFor="reportProcessId">Eigener Spielbericht verarbeitet</label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="nextgameId" defaultChecked={nextgame} onClick={event => {
                                            setNextgame(!nextgame);
                                        }} />
                                        <label className="form-check-label" htmlFor="nextgameId">Information zum nächsten Spieltag</label>
                                    </div>
                                    {
                                        user.rolle === "Admin" &&
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch" id="playerUpdateId" defaultChecked={playerUpdate} onClick={event => {
                                                setPlayerUpdate(!playerUpdate);
                                            }}/>
                                            <label className="form-check-label" htmlFor="playerUpdateId">Spieler update verfügbar</label>
                                        </div>
                                    }
                                    <Button disabled={loading} onClick={event => {
                                        setLoading(true)
                                        const flags: EmailFlags = {
                                            betwin: betwin,
                                            enddate: enddate,
                                            nextgame: nextgame,
                                            playerUpdate: playerUpdate,
                                            reportProcess: reportProcess
                                        }
                                        service.user().updateMailFlags(params.saison, user.token, flags, setUser, setError, setLoading);
                                    }}>Speicher Benachrichtigungseinstellung</Button>
                                </Form>
                            </Card.Body>
                        </Card>
            </Container>
    );
}