import BaseService from "./BaseService";

export default class UserService extends BaseService {

    public login(user: string, password: string, setUser: (u:User) => void, setError: (e: string) => void, setLoading: (p:boolean) => void) {
        const data = {
            'username': user,
            'password': password
        };
        fetch(this.url + "login", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(data)}
        )
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<User>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setUser(r))
        .catch(e => setError("Fehler beim Login"))
        .finally(() => setLoading(false));
    }

    public updatePassword(saison: number, token: string, password: string, setUser: (u:User) => void, setError: (e: string) => void, setLoading: (p:boolean) => void) {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/user/password", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            },
            method: "POST",
            body: password
        })
            .then(r => {
                if(r.ok) {
                    return r.text() as Promise<string>;
                } else {
                    return Promise.reject(r);
                }
            })
            .catch(e => {
                setError("Fehler beim ändern des Passwortes");
                setUser({token: "", name: "", rolle: ""});
            })
            .finally(() => setLoading(false));
    }

    public updateEmail(saison: number, token: string, email: string, setUser: (u:User) => void, setError: (e: string) => void, setLoading: (p:boolean) => void) {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/user/email", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            },
            method: "POST",
            body: email
        })
        .then(r => {
            if(r.ok) {
                return r.text() as Promise<string>;
            } else {
                return Promise.reject(r);
            }
        })
        .catch(e => {
            setError("Fehler beim ändern der email");
            setUser({token: "", name: "", rolle: ""});
        })
        .finally(() => setLoading(false));
    }

    public updateMailFlags(saison: number, token: string, emailflags: EmailFlags, setUser: (u:User) => void, setError: (e: string) => void, setLoading: (p:boolean) => void) {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/user/emailflags", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            method: "POST",
            body: JSON.stringify(emailflags)
        })
        .then(r => {
            if(r.ok) {
                return r.text() as Promise<string>;
            } else {
                return Promise.reject(r);
            }
        })
        .catch(e => {
            setError("Fehler beim änder der mail flags");
            setUser({token: "", name: "", rolle: ""});
        })
        .finally(() => setLoading(false));
    }

    public getConfig(saison: number, token: string, setUser: (u:User) => void, setData: (d: UserConfig) => void, setLoading: (p:boolean) => void): void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"member/user/config", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<UserConfig>>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }
}