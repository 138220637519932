import StatistikService from "./StatistikService";
import TeamService from "./TeamService";
import BetService from "./BetService";
import StaticDataService from "./StaticDataService";
import BaseService from "./BaseService";
import AdminService from "./AdminService";
import SpieltagService from "./SpieltagService";
import DashboardService from "./DashboardService";
import TreasuryService from "./TreasuryService";
import UserService from "./UserService";

export default class DataService extends BaseService {

    private static INSTANCE: DataService | null = null;

    private readonly statistikService: StatistikService;
    private readonly teamService: TeamService;
    private readonly betService: BetService;
    private readonly staticDataService: StaticDataService;
    private readonly adminService: AdminService;
    private readonly spieltagService: SpieltagService;
    private readonly dashboardService: DashboardService;
    private readonly treasuryService: TreasuryService;
    private readonly userService: UserService;

    constructor() {
        super();
        this.statistikService = new StatistikService();
        this.teamService = new TeamService();
        this.betService = new BetService();
        this.staticDataService = new StaticDataService();
        this.adminService = new AdminService();
        this.spieltagService = new SpieltagService();
        this.dashboardService = new DashboardService();
        this.treasuryService = new TreasuryService();
        this.userService = new UserService();
    }

    public static getInstance(): DataService {
        if(DataService.INSTANCE == null) {
            DataService.INSTANCE = new DataService();
        }
        return DataService.INSTANCE;
    }

    public getSaisons(setData: (p:Saison[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.url+"saisons")
            .then(r => r.json() as Promise<ApiResponse<Saison[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getOverview(saison: number, setOverview: (p:OverviewData) => void, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"overview")
            .then(r => r.json() as Promise<ApiResponse<OverviewData>>)
            .then(r => setOverview(r.data))
            .finally(() => setLoading(false));
    }

    public getSaisonResult(saison: number, setData: (p:SaisonResult) => void, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"result")
            .then(r => r.json() as Promise<ApiResponse<SaisonResult>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public team(): TeamService {
        return this.teamService;
    }

    public admin(): AdminService {
        return this.adminService;
    }

    public spieltag(): SpieltagService {
        return this.spieltagService;
    }

    public getSpielbericht(saison: number, tag: number, heim: number, gast: number, setData: (p: SpielBerichtData) => void, setLoading: (p:boolean) => void) : void {
        fetch(this.getUrl(saison)+"spielbericht/"+tag+"/"+heim+"/"+gast)
            .then(r => r.json() as Promise<ApiResponse<SpielBerichtData>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public saveSpielbericht(saison: number, data: SpielBerichtDataSave, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"bericht", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(data)}
        ).finally(() => setLoading(false));
    }

    public updateSpielbericht(saison: number, data: SpielBerichtDataSave, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison)+"bericht", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "PUT",
            body: JSON.stringify(data)}
        ).finally(() => setLoading(false));
    }

    public createSpielBericht(saison: number, spieltag: number, own: Team, enemy: Team, setData: (p:SpielBerichtDataSave) => void, setLoading: (p:boolean) => void) : void {
        fetch(this.getUrl(saison)+"bericht/"+spieltag+"/"+own.id+"/"+enemy.id)
            .then(r => r.json() as Promise<ApiResponse<SpielBerichtDataSave>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getSpielberichtListe(saison: number, token: string, setUser: (u:User) => void, setData: (d: SpielBerichtDataListElement[]) => void, setLoading: (p:boolean) => void) : void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"bericht/process", {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
        .then(r => {
            if(r.ok) {
                return r.json() as Promise<ApiResponse<SpielBerichtDataListElement[]>>;
            } else {
                return Promise.reject(r);
            }
        })
        .then(r => setData(r.data))
        .catch(e => setUser({token: "", name: "", rolle: ""}))
        .finally(() => setLoading(false));
    }

    public processSpielbericht(saison: number, id:number, token: string, setUser: (u:User) => void, setData: (d: SpielBerichtDataListElement[]) => void, setLoading: (p:boolean) => void) : void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"bericht/process/"+id, {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
            .then(r => {
                if(r.ok) {
                    return r.json() as Promise<ApiResponse<SpielBerichtDataListElement[]>>;
                } else {
                    return Promise.reject(r);
                }
            })
            .then(r => setData(r.data))
            .catch(e => setUser({token: "", name: "", rolle: ""}))
            .finally(() => setLoading(false));
    }

    public loescheSpielbericht(saison: number, id:number, token: string, setUser: (u:User) => void, setData: (d: SpielBerichtDataListElement[]) => void, setLoading: (p:boolean) => void) : void {
        const authToken = 'Bearer ' + token;
        fetch(this.getUrl(saison)+"bericht/delete/"+id, {
            headers: {
                'Accept': 'application/json',
                'Authorization': authToken
            }
        })
            .then(r => {
                if(r.ok) {
                    return r.json() as Promise<ApiResponse<SpielBerichtDataListElement[]>>;
                } else {
                    return Promise.reject(r);
                }
            })
            .then(r => setData(r.data))
            .catch(e => setUser({token: "", name: "", rolle: ""}))
            .finally(() => setLoading(false));
    }

    public staticData(): StaticDataService {
        return this.staticDataService;
    }

    public statistik(): StatistikService {
        return this.statistikService;
    }

    public dashboard(): DashboardService {
        return this.dashboardService;
    }

    public treasury(): TreasuryService {
        return this.treasuryService;
    }

    public user(): UserService {
        return this.userService;
    }

    public bet(): BetService {
        return this.betService;
    }
}