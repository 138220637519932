import BaseService from "./BaseService";

export default class StaticDataService extends BaseService {

    public getActions(setData: (p:Action[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.url+"actions")
            .then(r => r.json() as Promise<ApiResponse<Action[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getKickoff(setData: (p:KickoffEvent[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.url+"kickoff")
            .then(r => r.json() as Promise<ApiResponse<KickoffEvent[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getReactions(setData: (p:Action[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.url+"reactions")
            .then(r => r.json() as Promise<ApiResponse<Action[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getWetter(saison: number, tag: number, setData: (p:Wetter[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.getUrl(saison) + "spieltag/" + tag + "/wetter")
            .then(r => r.json() as Promise<ApiResponse<Wetter[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getWetterByArt(art: number, setData: (p:Wetter[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.url+"wetter/" + art )
            .then(r => r.json() as Promise<ApiResponse<Wetter[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getSkills(setData: (p:SkillDto[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.url+"skills")
            .then(r => r.json() as Promise<ApiResponse<SkillDto[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getNewMember(teamArt: number, setData: (p:Player[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.url+"member/" + teamArt )
            .then(r => r.json() as Promise<ApiResponse<Player[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

    public getTeamArten(setData: (p:TeamArt[]) => void, setLoading: (p:boolean) => void): void {
        fetch(this.url+"teamarten")
            .then(r => r.json() as Promise<ApiResponse<TeamArt[]>>)
            .then(r => setData(r.data))
            .finally(() => setLoading(false));
    }

}