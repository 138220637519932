import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import logo from "../asserts/images/blood_bros_league_logo.png"
import {Link} from "react-router-dom";
import Team from "../component/Team";
import furyBully from "../asserts/images/fury_bulls/icon.png";
import DataService from "../services/DataService";
import {emptyPlayer, emptyTeamOverview} from "../util/EmptyObjects";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import NewMemberModal from "../component/NewMemberModal";
import NewStaffModal from "../component/NewStaffModal";
import PlayerRow from "../component/PlayerRow";
import Form from "react-bootstrap/Form";
import NewPlayerRow from "../component/NewPlayerRow";
import Select from "react-select";

const mapToDropDownTeamArt = (data: TeamArt) : {label: string, value: any} => {
    return {label: data.name, value: data};
};

export default function Roster() {
    const service = useMemo(() => DataService.getInstance(), []);

    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalStaff, setShowModalStaff] = useState<boolean>(false);
    const [newMember, setNewMember] = useState<Player[]>([]);
    const [team, setTeam] = useState<TeamOverview>(emptyTeamOverview);
    const [teamArten, setTeamArten] = useState<TeamArt[]>([]);
    const [teamart, setTeamArt] = useState<TeamArt|null>(null);

    useEffect(() => {
        setLoading(true);
        team.kasse = 1000000;
        service.staticData().getTeamArten(setTeamArten, setLoading);
    }, [service, team]);

    useEffect(() => {
        if(teamart != null) {
            setLoading(true);
            service.staticData().getNewMember(teamart.id, setNewMember, setLoading);
            team.name = 'Random '+teamart.name;
            team.spieler = [];
            team.value.all = 0;
            team.value.actual =0;
            team.kasse = 1000000;
        }
    }, [service, team, teamart]);

    return (
        loading ?
            <div className="spinner-grow" role="status"/>
        :
            <Container className="Team">
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        <Select
                            options={teamArten.map(mapToDropDownTeamArt)}
                            placeholder={'Wähle ein Team'}
                            onChange={(v) => {
                                if(v!=null) {
                                    setTeamArt(v.value);
                                }
                            }}
                        ></Select>
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                {
                    teamart != null &&
                        <>
                            <Row>
                                <Col>
                                    <Button variant="primary" size="sm" disabled={team.spieler.length >= 16} onClick={(e) => {
                                        setShowModal(true);
                                    }}>
                                        Neuer Mitspieler&nbsp;<FontAwesomeIcon icon={faPlus}/>
                                    </Button>
                                    &nbsp;
                                    <Button variant="primary" size="sm" onClick={(e) => {
                                        setShowModalStaff(true);
                                    }}>
                                        Neues Teammitglied&nbsp;<FontAwesomeIcon icon={faPlus}/>
                                    </Button>
                                </Col>
                                <Col>
                                    <NewMemberModal show={showModal} member={newMember} handleClose={() => setShowModal(false)} handleAdd={p => {
                                        setShowModal(false);
                                        if(team.spieler.length >= 16) {
                                            alert("Das Team besteht schon aus 16 Spielern!");
                                        } else if(team.kasse < p.kosten) {
                                            alert("Der Spieler ist zu teuer!");
                                        } else {
                                            team.spieler.push(p);
                                            team.kasse -= p.kosten;
                                            team.value.all += p.kosten;
                                            team.value.actual += p.kosten;
                                        }
                                    }} />
                                    <NewStaffModal
                                        show={showModalStaff}
                                        heimId={team.id}
                                        heimReroll={teamart.reroll}
                                        gastId={null}
                                        gastReroll={null}
                                        handleClose={() => setShowModalStaff(false)}
                                        handleAdd={s => {
                                            if(team.kasse < s.kosten) {
                                                alert("Das Mitglied ist zu teuer!");
                                            } else {
                                                // if(window.confirm("Willst du wirklich "+ s.name+ " kaufen?")) {
                                                //     service.team().addStaff(params.saison, user.token, setUser, s, setTeam, setLoading);
                                                // }
                                            }
                                            setShowModalStaff(false);
                                        }} />
                                    &nbsp;
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Control type="text" placeholder={"Teamname"} style={{marginTop: 25, marginBottom: 15}} defaultValue={team.name} onChange={event => team.name = event.target.value} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table className="col-sm-12 table table-bordered table-striped table-sm">
                                        <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Name (Spielernummer)</th>
                                            <th>Position</th>
                                            <th>BE</th>
                                            <th>ST</th>
                                            <th>GE</th>
                                            <th>WG</th>
                                            <th>RW</th>
                                            <th>Fertigkeiten</th>
                                            <th>Anwerbekosten</th>
                                            <th>Verf. SSP</th>
                                            <th>VNS</th>
                                            <th>HV</th>
                                            <th>AD</th>
                                            <th>Moment. Wert</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            team.spieler.map((player, index) => <NewPlayerRow key={player.id}
                                                                                              player={player}
                                                                                              pos={index + 1}
                                                                                              deleteRow={p => team.spieler = team.spieler.slice(p-1,1)}
                                            />)
                                        }
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table className="col-sm-12 table table-bordered table-sm">
                                        <tbody>
                                        <tr>
                                            <td>Wiederholungswürfe:</td>
                                            <td className="textCenter">{team.rerolls.anzahl}</td>
                                            <td className="textCenter">X</td>
                                            <td className="textRight">{team.rerolls.kosten.toLocaleString()}</td>
                                            <td className="textRight">{(team.rerolls.anzahl * team.rerolls.kosten).toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td>Trainerassistenten:</td>
                                            <td className="textCenter">{team.trainerass}</td>
                                            <td className="textCenter">X</td>
                                            <td className="textRight">10.000</td>
                                            <td className="textRight">{(team.trainerass * 10000).toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td>Cheerleader:</td>
                                            <td className="textCenter">{team.cheerleader}</td>
                                            <td className="textCenter">X</td>
                                            <td className="textRight">10.000</td>
                                            <td className="textRight">{(team.cheerleader * 10000).toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td>Sanitäter:</td>
                                            <td className="textCenter">{team.sani}</td>
                                            <td className="textCenter">X</td>
                                            <td className="textRight">50.000</td>
                                            <td className="textRight">{(team.sani * 50000).toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td>Teamwert:</td>
                                            <td colSpan={4} className="textRight">{team.value.all.toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td>Momentaner Teamwert:</td>
                                            <td colSpan={4} className="textRight">{team.value.actual.toLocaleString()}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </>
                }
            </Container>
    );
}